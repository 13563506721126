// var stickyHeader = false;
// var flyoutNav = false;
// var fullscreenNav = false;
// var hoverSensitiveNav = false;

// var carouselBackgroundVideoYouTubeID = ''; // see https://fresco-docs.netlify.com/#/carousel/background-video

var countUpStats = true;
// var countUpStatsDuration = 3000;

// var homeQuickGivingPreFooter = false;
var transformHomepageDonationAmountImages = true;

// var feedTitleLinks = ['/first-feed-title-link', '/second-feed-title-link']; // see https://fresco-docs.netlify.com/#/home-feeds/feed-title-links
// var feedItemsCarouselBreakpoint = 576; // see https://fresco-docs.netlify.com/#/home-feeds/feed-items-carousel
// var feedItemsCarouselAutoplay = true;
// var feedItemsCarouselAutoplaySpeed = 4000;

// var subsiteHijackMainNav = ['example-subsite-name'];
// var subsiteHijackMainLogoClickDestination = ['example-subsite-name'];
// var subsiteTitleNotClickable = true;

var sidebarCards = true;

// Feed & feature re-ordering
if ($('body').hasClass('homepage')) {
  $('.StoriesfromourhomeFeed').insertAfter('.homeFeatures');
}

// Add hover class to all elements of search fieldset
$('#siteSearch, .searchContainer button').hover(function () {
  $('#siteSearch, .searchContainer button').toggleClass('hover');
})

// Consistent button height
var headerButtonHeight = $('.socialIcons a').css('height');
$('.mainCallToAction a.cta-button').css('height', headerButtonHeight);
$('.header-search button').css('height', headerButtonHeight);
$('#siteSearch').css('height', headerButtonHeight);

// Header search width same as maincalltoaction width
function resizeSearchContainer() {
  var callToActionWidth = $('.mainCallToAction').css('width');
  $('.headerContent .header-search').css('width', callToActionWidth);
}

resizeSearchContainer();

// Add class to features when adjacent
function adjacentFeatures() {
  if ($('body').hasClass('homepage')) {
    var windowWidth = $(window).width();
    var featureWidth = $('.homeBox1').width();
    if (featureWidth / windowWidth >= 0.6) {
      $('.homeBox1, .homeBox2').addClass('fullwidth');
    } else {
      $('.homeBox1, .homeBox2').removeClass('fullwidth');
    }
  }
}
adjacentFeatures()

// Do functions on window resize
$(window).resize(function () {
  resizeSearchContainer();
  adjacentFeatures()
})

// Making posts hover only - no click
$('.listedPost.PostCategory_unclickable').each(function () {
  $(this).find('a').removeAttr("href");
  $(this).find('a').prop("onclick", null).css("cursor", "auto");
  $(this).prop("onclick", null).css("cursor", "auto");
});